import { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";

import {
  ChevronLeft,
  PencilLine,
  Search,
  Folder,
  ChevronUp,
  MoreHorizontal,
  Trash,
  SquarePen,
  ChevronDown,
  CheckCircle2,
  X,
} from "lucide-react";

import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import {
  createNewChat,
  createNewFolder,
  getUserHistory,
  getChatHistory,
  deleteStructure,
  removeStructureFromUserHistory,
  updateChatStructureName,
  updateStructureName,
  dragFileToFolder,
  updateFileParent,
} from "../features/chats/chatSlice";
import { loadStripe } from "@stripe/stripe-js";

import { useAppContext } from "../AppContext";
import PricingModal from "./modals/PricingModal";
import ProfileButton from "./ProfileButton";
import { setTemprature } from "../features/auth/authSlice";
import { file } from "@babel/types";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const LeftSidebar = ({ collapsible, toggleSidebar }) => {
  const dispatch = useDispatch();

  const { loginInfo, user, temprature } = useSelector((state) => state.auth);
  const {
    newChat,
    newFolder,
    userHistory,
    adData,
    isLoading,
    isError,
    message,
  } = useSelector((state) => state.chat);

  const [open, setOpen] = useState(true);
  const { gptModel, updateGPTModel, updateCurrentChat } = useAppContext();
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [themeChecked, setThemeChecked] = useState(true);

  const [value, setValue] = useState(1);

  const handleChange = (event) => {
    if (user?.data.proAccount) {
      dispatch(setTemprature(event.target.value));
    } else {
      setShowPricingModel(true);
    }
  };

  const handleToggle = () => {
    setThemeChecked(!themeChecked);
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.body.classList.remove("light", "dark");
    document.body.classList.add(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  const [selectedChatIndex, setSelectedChatIndex] = useState(0);
  const [selectedFolderIndex, setSelectedFolderIndex] = useState(-1);

  const [chats, setChats] = useState([]);

  const addNewChat = () => {
    dispatch(createNewChat(loginInfo?.token));

    // Check if toggleSidebar exists and is a function before calling it
    if (typeof toggleSidebar === "function") {
      toggleSidebar();
    }
  };

  const addNewFolder = () => {
    dispatch(createNewFolder(loginInfo?.token));
  };

  const handleChatSelection = (chatId) => {
    updateCurrentChat(chatId);
    const data = { id: chatId, token: loginInfo.token };
    dispatch(getChatHistory(data));
  };

  const delStructure = (structureId) => {
    const data = { id: structureId, token: loginInfo.token };
    dispatch(removeStructureFromUserHistory(structureId));
    dispatch(deleteStructure(data));
  };

  useEffect(() => {
    if (isError) {
      console.log(message);
    }

    if (userHistory || newChat) {
      setChats(userHistory);
    }

    if (newChat) {
      updateCurrentChat(newChat);
    }

    if (adData) {
      if (open && !user?.data.proAccount) {
        const currentAd = adData?.filter((ad) => ad.position === "TopLeft")[0];
        document.getElementById("topLeftAd").innerHTML = currentAd.htmlCode;
      }
    }

    const storedTheme = localStorage.getItem("theme") || "dark";
    setTheme(storedTheme);
    document.body.classList.add(storedTheme);
  }, [theme, newChat, newFolder, userHistory, adData, open]);

  const File = ({ index, chatId, name, type, onDrop }) => {
    const [, drag] = useDrag({
      type: "FILE",
      item: { index, chatId, name, type },
    });

    const [, drop] = useDrop({
      accept: "FILE",
      drop: (item) => {
        if (item.type === "File") {
          return; // Do nothing
        }
        onDrop(item);
      },
    });

    const [editChat, setEditChat] = useState(-1);
    const [viewChatOptions, setViewChatOptions] = useState(-1);

    const [editable, setEditable] = useState(false);
    const [newName, setNewName] = useState(name);

    const handleCheckClick = () => {
      const payload = { id: chatId, token: loginInfo.token, title: newName };
      dispatch(updateStructureName(payload));
      dispatch(updateChatStructureName({ id: chatId, name: newName }));
      setEditChat(-1);
      setEditable(false);
    };

    const handleNameChange = (e) => {
      setNewName(e.target.value);
    };

    return (
      <div
        ref={(node) => drag(drop(node))}
        key={index}
        className={`flex flex-row justify-between gap-1 p-1 rounded-lg cursor-pointer min-w-0 ${
          selectedChatIndex == index ? "bg-dark text-white" : "bg-componentBg"
        }`}
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
            />
          </svg>
        </span>
        <p
          className="text-[14px] max-w-[90%] whitespace-nowrap overflow-hidden overflow-ellipsis"
          onClick={() => {
            handleChatSelection(chatId);
            setSelectedChatIndex(index);
          }}
        >
          {editable ? (
            <input
              type="text"
              className="w-3/3 bg-pageBg text-textColor"
              value={newName}
              onChange={handleNameChange}
              autoFocus
            />
          ) : (
            name
          )}
        </p>
        <div className="flex items-center ms-auto">
          {editChat == index ? (
            <span className="flex">
              <CheckCircle2
                size={20}
                fill="#20FDC9"
                onClick={handleCheckClick}
                className="text-dark border-primary"
              />
              <X
                size={20}
                onClick={() => {
                  setEditChat(-1);
                  setEditable(false);
                }}
              />
            </span>
          ) : (
            <span
              className="flex items-center gap-1"
              onMouseEnter={() => setViewChatOptions(index)}
              onMouseLeave={() => setViewChatOptions(-1)}
            >
              <MoreHorizontal
                size={20}
                className={`${viewChatOptions == index && "hidden"}`}
              />
              {viewChatOptions == index && (
                <>
                  <SquarePen
                    onClick={() => {
                      setEditChat(index);
                      setEditable(true);
                    }}
                    size={20}
                  />
                  <Trash
                    size={20}
                    className="text-redDelete"
                    onClick={() => delStructure(chatId)}
                  />
                </>
              )}
            </span>
          )}
        </div>
      </div>
    );
  };

  const FolderItem = ({ index, folderId, name, onDrop }) => {
    const [, drop] = useDrop({
      accept: "FILE",
      drop: (item) => onDrop(item, folderId),
    });

    const [editFolder, setEditFolder] = useState(-1);
    const [viewFolderOptions, setViewFolderOptions] = useState(-1);
    const [editableFolder, setEditableFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState(name);

    const handleCheckClick = () => {
      const payload = {
        id: folderId,
        token: loginInfo.token,
        title: newFolderName,
      };
      dispatch(updateStructureName(payload));
      dispatch(updateChatStructureName({ id: folderId, name: newFolderName }));
      setEditFolder(-1);
      setEditableFolder(false); // Set editableFolder to false when done editing
    };

    const handleXClick = () => {
      setEditFolder(-1);
      setEditableFolder(false); // Set editableFolder to false when user cancels editing
    };

    return (
      <div
        ref={drop}
        key={index}
        className={`${
          selectedFolderIndex == index && selectedChatIndex === -1
            ? "bg-dark text-white"
            : "bg-componentBg"
        } text-textColor rounded-lg flex justify-start items-center cursor-pointer min-w-0 -mt-1.5`}
      >
        <div className="px-2 py-1">
          <Folder fill="currentColor" size={20} strokeWidth={0} />
        </div>
        <p
          className="rounded-lg max-w-[90%] whitespace-nowrap overflow-hidden overflow-ellipsis"
          onClick={() => {
            setSelectedChatIndex(-1);
            setSelectedFolderIndex(selectedFolderIndex === index ? -1 : index);
          }}
        >
          {editableFolder ? (
            <input
              type="text"
              className="w-3/3 bg-pageBg text-textColor"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              autoFocus
            />
          ) : (
            name
          )}
        </p>
        {editFolder == index ? (
          <span className="flex p-2 ms-auto">
            <CheckCircle2
              size={20}
              onClick={handleCheckClick}
              fill="#20FDC9"
              className="text-dark border-primary"
            />
            <X size={20} onClick={handleXClick} />
          </span>
        ) : (
          <div className="flex items-center -space-x-2 ms-auto">
            <span className="px-1 py-1">
              <ChevronDown
                onClick={() =>
                  setSelectedFolderIndex((prevIndex) =>
                    prevIndex === index ? -1 : index
                  )
                }
                className={`transition-transform transform duration-200 ease-in-out ${
                  selectedFolderIndex === index ? "rotate-180" : ""
                }`}
                size={20}
              />
            </span>
            <span
              className="p-2 rounded-lg flex justify-center gap-1 cursor-pointer"
              onMouseEnter={() => setViewFolderOptions(index)}
              onMouseLeave={() => setViewFolderOptions(-1)}
            >
              {viewFolderOptions == index ? (
                <>
                  <SquarePen
                    size={20}
                    onClick={() => {
                      setEditFolder(index);
                      setEditableFolder(true);
                    }}
                  />
                  <Trash
                    size={20}
                    className="text-redDelete"
                    onClick={() => delStructure(folderId)}
                  />
                </>
              ) : (
                <>
                  <MoreHorizontal size={20} />
                </>
              )}
            </span>
          </div>
        )}
      </div>
    );
  };

  const handleDrop = (item, folderId) => {
    dispatch(dragFileToFolder({ chatId: item.chatId, parent: folderId }));
    dispatch(
      updateFileParent({
        chatId: item.chatId,
        parent: folderId,
        token: loginInfo.token,
      })
    );
  };

  const [showPricingModel, setShowPricingModel] = useState(false);
  const modelSwitch = (value) => {
    if (!user?.data.proAccount) {
      setShowPricingModel(!showPricingModel);
    } else {
      updateGPTModel(value);
    }
  };

  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredChats = chats.filter(
    (chat) =>
      chat.text.toLowerCase().includes(searchQuery.toLowerCase()) &&
      chat.type == "File"
  );

  const displayChats = searching ? filteredChats : chats;

  return (
    <div
      className={` ${
        open ? "w-[16rem] p-4 rounded-3xl" : "w-[4rem] py-4 px-2 rounded-full"
      } bg-componentBg h-full relative duration-300`}
    >
      {open && collapsible && (
        <ChevronLeft
          className={`absolute bg-chevronBtnBg text-chevronColor rounded-full cursor-pointer -right-3 top-[4.2rem] w-7 ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
      )}

      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-3 overflow-hidden">
          <div
            className={`bg-dark flex flex-col justify-evenly p-1.5 rounded-${
              open ? "lg" : "full"
            }`}
          >
            {open ? (
              <>
                <div className="flex justify-evenly">
                  {" "}
                  {/* Container for the GPT 3.5 and GPT 4 buttons */}
                  <button
                    className={`${
                      gptModel == "3.5" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                    onClick={() => modelSwitch("3.5")}
                  >
                    <svg
                      width="15"
                      height="18"
                      viewBox="0 0 15 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.8044 8.01741H10.0082V1.69741C10.0082 0.22275 9.18479 -0.0756932 8.18036 1.0303L7.45645 1.82908L1.33033 8.58797C0.488785 9.50963 0.841693 10.2645 2.10854 10.2645H4.90466V16.5845C4.90466 18.0592 5.72811 18.3576 6.73254 17.2516L7.45645 16.4528L13.5826 9.69396C14.4241 8.7723 14.0712 8.01741 12.8044 8.01741Z"
                        fill="#20FDC9"
                      />
                    </svg>
                    GPT 3.5
                  </button>
                  <button
                    className={`${
                      gptModel == "4" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                    onClick={() => modelSwitch("4")}
                  >
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="4.20524"
                        cy="8.14091"
                        r="4.06413"
                        fill="#20FDC9"
                      />
                      <circle
                        cx="11.9269"
                        cy="3.67033"
                        r="2.84489"
                        fill="#20FDC9"
                      />
                      <circle
                        cx="10.3011"
                        cy="13.4243"
                        r="2.03207"
                        fill="#20FDC9"
                      />
                    </svg>
                    GPT 4o
                  </button>
                </div>
                {/* New "Image Generation" button */}
                <div className="flex justify-evenly">
                  {" "}
                  {/* Container for the GPT 3.5 and GPT 4 buttons */}
                  <button
                    className={`${
                      gptModel == "claude" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                    onClick={() => modelSwitch("claude")}
                  >
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="4.20524"
                        cy="8.14091"
                        r="4.06413"
                        fill="#20FDC9"
                      />
                      <circle
                        cx="11.9269"
                        cy="3.67033"
                        r="2.84489"
                        fill="#20FDC9"
                      />
                      <circle
                        cx="10.3011"
                        cy="13.4243"
                        r="2.03207"
                        fill="#20FDC9"
                      />
                    </svg>
                    Claude
                  </button>
                  <button
                    className={`${
                      gptModel == "dalle-3" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                    onClick={() => modelSwitch("dalle-3")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#20FDC9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" />
                      <circle cx="8.5" cy="8.5" r="1.5" />
                      <path d="M20.4 14.5L16 10 4 20" />
                    </svg>
                    Image
                  </button>
                </div>

                {/* Llma3 */}
                <div className="flex justify-evenly">
                  {" "}
                  {/* Container for the GPT 3.5 and GPT 4 buttons */}
                  <button
                    className={`${
                      gptModel == "Llama3" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                    onClick={() => modelSwitch("Llama3")}
                  >
                    <svg
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="4.20524"
                        cy="8.14091"
                        r="4.06413"
                        fill="#20FDC9"
                      />
                      <circle
                        cx="11.9269"
                        cy="3.67033"
                        r="2.84489"
                        fill="#20FDC9"
                      />
                      <circle
                        cx="10.3011"
                        cy="13.4243"
                        r="2.03207"
                        fill="#20FDC9"
                      />
                    </svg>
                    Llama3
                  </button>

                  <button
                    className={`${
                      gptModel == "vision" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                    onClick={() => modelSwitch("vision")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#20FDC9"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <rect x="3" y="3" width="18" height="18" rx="2" />
                      <circle cx="8.5" cy="8.5" r="1.5" />
                      <path d="M20.4 14.5L16 10 4 20" />
                    </svg>
                   
                    Vision
                  </button>
              
                </div>

                <div className="flex justify-evenly">
                  {" "}
                  {/* Container for the GPT 3.5 and GPT 4 buttons */}
                  <button
                    className={`${
                      gptModel == "o1" ? "bg-charcoal" : ""
                    } flex-1 flex items-center gap-1 text-white p-2 rounded-lg font-semibold justify-center`}
                     onClick={() => modelSwitch("o1")}
                  >
                   <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8044 8.01741H10.0082V1.69741C10.0082 0.22275 9.18479 -0.0756932 8.18036 1.0303L7.45645 1.82908L1.33033 8.58797C0.488785 9.50963 0.841693 10.2645 2.10854 10.2645H4.90466V16.5845C4.90466 18.0592 5.72811 18.3576 6.73254 17.2516L7.45645 16.4528L13.5826 9.69396C14.4241 8.7723 14.0712 8.01741 12.8044 8.01741Z"
                      fill="#20FDC9"
                    />
                  </svg>
                  GPT o1
                  </button>

                 
              
                </div>
              </>
            ) : (
              <div className="rounded-full p-2">
                {gptModel == "3.5" ? (
                  <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8044 8.01741H10.0082V1.69741C10.0082 0.22275 9.18479 -0.0756932 8.18036 1.0303L7.45645 1.82908L1.33033 8.58797C0.488785 9.50963 0.841693 10.2645 2.10854 10.2645H4.90466V16.5845C4.90466 18.0592 5.72811 18.3576 6.73254 17.2516L7.45645 16.4528L13.5826 9.69396C14.4241 8.7723 14.0712 8.01741 12.8044 8.01741Z"
                      fill="#20FDC9"
                    />
                  </svg>
                ) : (
                  <svg
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="4.20524"
                      cy="8.14091"
                      r="4.06413"
                      fill="#20FDC9"
                    />
                    <circle
                      cx="11.9269"
                      cy="3.67033"
                      r="2.84489"
                      fill="#20FDC9"
                    />
                    <circle
                      cx="10.3011"
                      cy="13.4243"
                      r="2.03207"
                      fill="#20FDC9"
                    />
                  </svg>
                )}
              </div>
            )}
          </div>

          {open ? (
            <div>
              {!user?.data?.proAccount && (
                <div
                  id="topLeftAd"
                  className="flex justify-center border-2 border-borderColor p-2 rounded-lg"
                ></div>
              )}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <ChevronLeft
                className={`bg-chevronBtnBg rounded-full text-chevronColor cursor-pointer ${
                  !open && "rotate-180"
                }`}
                onClick={() => setOpen(!open)}
              />
              <div className="border-[1px] border-gray-500 mt-5 w-full"></div>
            </div>
          )}

          <div>
            {open ? (
              <button
                className={`bg-brightTeal w-full flex items-center justify-between text-dark px-3 py-2 rounded-lg font-[500]`}
                onClick={addNewChat}
              >
                {isLoading ? "Loading..." : "New Chat"}
                <PencilLine size={20} />
              </button>
            ) : (
              <div className="bg-dark p-3 rounded-full">
                <PencilLine
                  size={20}
                  className="text-brightTeal cursor-pointer"
                  onClick={() => {
                    dispatch(createNewChat(loginInfo?.token));
                    setOpen(!open);
                  }}
                />
              </div>
            )}
          </div>

          {!open ? (
            <div className="flex flex-col gap-4">
              <div className="bg-searchIconBg rounded-full p-3">
                <Search
                  className="text-textColor cursor-pointer"
                  onClick={() => {
                    setSearching(!searching);
                    setOpen(!open);
                  }}
                />
              </div>
              <div className="bg-searchIconBg rounded-full p-3">
                <Folder
                  className="text-textColor cursor-pointer"
                  onClick={() => {
                    dispatch(createNewFolder(loginInfo?.token));
                    setOpen(!open);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="border-2 border-borderColor p-2 rounded-lg text-textColor overflow-auto min-h-[5rem]">
              <div className="flex items-center justify-between gap-2 min-w-0">
                {!searching ? (
                  <>
                    <div className="w-2/3 border-2 border-borderColor p-2 rounded-lg text-center flex items-center justify-center gap-1 cursor-pointer">
                      <div>
                        <Folder fill="currentColor" size={20} strokeWidth={0} />
                      </div>
                      <p
                        className="whitespace-nowrap overflow-hidden overflow-ellipsis"
                        onClick={addNewFolder}
                      >
                        New Folder
                      </p>
                    </div>
                    <span
                      className="w-1/3 border-2 border-borderColor p-2 rounded-lg flex justify-center"
                      onClick={() => setSearching(!searching)}
                    >
                      <Search
                        size={20}
                        className="text-textColor cursor-pointer"
                      />
                    </span>
                  </>
                ) : (
                  <div className="flex items-center">
                    <input
                      className="border-b-2 border-borderColor w-full p-2 outline-none focus:border-b-4 focus:border-borderColor my-2 bg-componentBg"
                      type="text"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setSearching(!!e.target.value);
                      }}
                      placeholder="Search a chat"
                      required
                    />
                    <X
                      className="cursor-pointer"
                      size={20}
                      onClick={() => setSearching(false)}
                    />
                  </div>
                )}
              </div>

              <div className="mt-1">
                {displayChats?.map(
                  (folder, folderIndex) =>
                    folder.type === "Folder" && (
                      <>
                        <FolderItem
                          key={folderIndex}
                          index={folderIndex}
                          folderId={folder.id}
                          name={folder.text}
                          onDrop={handleDrop}
                        />

                        <div className="ps-3">
                          {displayChats.map(
                            (file, index) =>
                              file.type == "File" &&
                              file.parent == folder.id &&
                              selectedFolderIndex === folderIndex && (
                                <File
                                  key={index}
                                  index={index}
                                  chatId={file.id}
                                  name={file.text}
                                  type={file.type}
                                  onDrop={handleDrop}
                                />
                              )
                          )}
                        </div>
                      </>
                    )
                )}

                <div className="px-1">
                  {displayChats?.map(
                    (chat, index) =>
                      chat.type === "File" &&
                      (chat.parent === 0 || searching) && (
                        <File
                          key={chat.id}
                          index={index}
                          chatId={chat.id}
                          name={chat.text}
                          type={chat.type}
                          onDrop={handleDrop}
                        />
                      )
                  )}
                </div>
              </div>
            </div>
          )}

          <div
            className={`
          flex justify-between ${open ? "block" : "hidden"}`}
          >
            <p className="text-textColor text-lg">Theme</p>
            <label className="cursor-pointer grid place-items-center">
              <input
                type="checkbox"
                onChange={handleToggle}
                value="synthwave"
                checked={themeChecked}
                className="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2 border-0"
              />
              <svg
                className="col-start-1 row-start-1 stroke-base-100 fill-base-100"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="5" />
                <path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
              </svg>
              <svg
                className="col-start-2 row-start-1 stroke-base-100 fill-base-100"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
              </svg>
            </label>
          </div>

          <div className="flex flex-col items-center space-y-2">
            <input
              type="range"
              min="0"
              max="1"
              step="0.1"
              value={temprature}
              onChange={handleChange}
              className="range range-xs " // Use TailwindCSS and DaisyUI classes for styling
              style={{ "--range-shdw": "var(--fallback-a, #00F4BA)" }}
            />
            <div className="text-center text-textColor">
              GPT Temperature: {temprature}
            </div>
          </div>
        </div>

        {/* Profile Button */}
        <ProfileButton
          open={open}
          handleToggle={handleToggle}
          themeChecked={themeChecked}
        />
      </div>
      {showPricingModel && (
        <>
          <Elements stripe={stripePromise}>
            <PricingModal
              open={showPricingModel}
              setOpen={setShowPricingModel}
            />
          </Elements>
        </>
      )}
    </div>
  );
};
export default LeftSidebar;
